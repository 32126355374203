import React from 'react';
import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="App-link">
          <code>nclabs.dev.br</code><br />
          <code style={{ fontSize: 16 }}>(47) 3275-2926</code>
          <code style={{ fontSize: 16 }}> | ncinfo@ncinfo.com.br</code>
        </p>

      </header>
    </div>
  );
}

export default App;
